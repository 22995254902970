import DocumentsForm from '@nord/ui/src/components/DocumentsForm'
import Icon from '@nord/ui/src/components/Icon'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import React from 'react'
import { Alert } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import X from 'react-feather/dist/icons/x'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Switch, Route, useRouteMatch, Link, Redirect } from 'react-router-dom'

import CloseNavItem from '../../../components/CloseNavItem'
import ModalPage from '../../../components/ModalPage'
import { CHART_TYPE_PATHS } from '../../../configuration/constants'
import { useAppContext } from '../../../context/AppContext'

import AccountCreations from './AccountCreations'
import CashDeposit from './CashDeposit'
import CashWithdrawal from './CashWithdrawal'
import ChangePassword from './ChangePassword'
import Information from './Information'
import Invoices from './Invoices'
import Referrals from './Referrals'

const validProfileSubpages = {
  oprettelser: () => true,
  dokumenter: () => true,
  adgangskode: () => true,
  fakturaer: () => true,
  kontant_udbetaling: () => true,
  kontant_indbetaling: ({ accountType }: { accountType: string }) =>
    accountType === 'private_account',
  henvisning: () => true,
}

const profileActiveKeys = {
  null: 'accountInformation',
  dokumenter: 'documents',
  fakturaer: 'invoices',
  // eslint-disable-next-line camelcase
  kontant_indbetaling: 'cashDeposit',
  oprettelser: 'accountsCreation',
  adgangskode: 'changePassword',
  henvisning: 'referrals',
}

const ProfilePage = () => {
  const { chartType } = useAppContext()

  const { email, accountType, country } = useSelector(selectCurrentUser)
  // @ts-expect-error TS(2339) FIXME: Property 'appState' does not exist on type 'unknow... Remove this comment to see the full error message
  const { appState, id: currentPortfolioId } = useObjectSelector(selectCurrentPortfolio)

  const isCompany = accountType === 'company_account'
  const { url } = useRouteMatch()
  const routeMatch = useRouteMatch('/konto/:subpage')
  const profileSubpage = routeMatch && routeMatch.params.subpage

  const chartParam = Object.keys(CHART_TYPE_PATHS).find(
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    (key) => CHART_TYPE_PATHS[key] === chartType,
  )

  const isInvested = appState === 'invested' || appState === 'deposit_recieved'

  let defaultBackParam
  if (isInvested) defaultBackParam = chartParam
  else defaultBackParam = 'information'

  const backUrl = currentPortfolioId
    ? `/portefoeljer/${currentPortfolioId}/${defaultBackParam}`
    : '/'

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (profileSubpage && !validProfileSubpages[profileSubpage]({ accountType }))
    return <Redirect to={url} />

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const activeSection = profileActiveKeys[profileSubpage]

  return (
    <Tab.Container defaultActiveKey={activeSection}>
      <ModalPage
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; title: string; backUrl:... Remove this comment to see the full error message
        title="Min konto"
        backUrl={backUrl}
        modalHeaderClassName="p-0"
        header={
          <>
            <Nav variant="tabs" className="w-100 d-none d-lg-flex flex-column flex-lg-row" fill>
              <Nav.Item>
                <Nav.Link as={Link} to={url} eventKey="accountInformation" className="link-dark">
                  Mine informationer
                </Nav.Link>
              </Nav.Item>
              {!isCompany && (
                <Nav.Item className="d-none d-lg-block">
                  <Nav.Link
                    as={Link}
                    to={`${url}/kontant_indbetaling/opret`}
                    eventKey="cashDeposit"
                    className="link-dark"
                  >
                    Kontant indbetaling
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item className="d-none d-lg-block">
                <Nav.Link
                  as={Link}
                  to={`${url}/dokumenter`}
                  eventKey="documents"
                  className="link-dark"
                >
                  Dokumenter
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="d-none d-lg-block">
                <Nav.Link
                  as={Link}
                  to={`${url}/fakturaer`}
                  eventKey="invoices"
                  className="link-dark"
                >
                  Fakturaer
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={`${url}/oprettelser`}
                  eventKey="accountsCreation"
                  className="link-dark"
                >
                  Depotoprettelser
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={`${url}/henvisning`}
                  eventKey="referrals"
                  className="link-dark"
                >
                  Henvisning
                </Nav.Link>
              </Nav.Item>
              <CloseNavItem backUrl={backUrl} />
            </Nav>
            <div className="d-block ml-auto p-2 d-lg-none">
              <Link to={backUrl} className="link-dark">
                <Icon icon={X} size={2} />
              </Link>
            </div>
          </>
        }
      >
        <Tab.Content>
          <Switch>
            <Tab.Pane as={Route} path={url} eventKey="portfolioInformation" exact>
              <Information />
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/henvisning`} eventKey="referrals" exact>
              <Referrals />
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/dokumenter`} eventKey="documents" exact>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ email: any; }' is not assignable to type '... Remove this comment to see the full error message */}
              <DocumentsForm email={email} />
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/fakturaer`} eventKey="invoices" exact>
              <Invoices />
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/kontant_udbetaling/verificer`} exact>
              <CashWithdrawal>
                {country !== 'DK' && (
                  <Alert variant="warning">
                    Da du ikke er bosat i Danmark, kan vi ikke verificere dig med MitID. I stedet
                    skal vi bruge en udfyldt overførselsanmodning. Kontakt os venligst på chat'en
                    eller ved at skrive til{' '}
                    <a href="mailto:hello@nord.investments">hello@nord.investments</a>, så vender vi
                    tilbage hurtigst muligt. Efter vi har modtaget anmodningen, vil den blive
                    behandlet inden for 48 timer.
                  </Alert>
                )}
                <CashWithdrawal.StartPage />
              </CashWithdrawal>
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/kontant_udbetaling/opret`} exact>
              <CashWithdrawal>
                <CashWithdrawal.WithdrawalPage />
              </CashWithdrawal>
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/oprettelser`} eventKey="accountsCreation" exact>
              <AccountCreations />
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/adgangskode`} eventKey="changePassword" exact>
              <ChangePassword />
            </Tab.Pane>
            {!isCompany && (
              <Tab.Pane
                as={Route}
                path={`${url}/kontant_indbetaling/opret`}
                eventKey="cashDeposit"
                exact
              >
                <CashDeposit>
                  <CashDeposit.StartPage />
                </CashDeposit>
              </Tab.Pane>
            )}
            {!isCompany && (
              <Tab.Pane
                as={Route}
                path={`${url}/kontant_indbetaling/:paymentId`}
                eventKey="cashDeposit"
                exact
              >
                <CashDeposit>
                  <CashDeposit.ResultPage />
                </CashDeposit>
              </Tab.Pane>
            )}
          </Switch>
        </Tab.Content>
      </ModalPage>
    </Tab.Container>
  )
}

export default ProfilePage
