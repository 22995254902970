import { getPortfolioChart, getPortfolioChartLive } from '@nord/api'
import FullScreenSection from '@nord/ui/src/components/FullScreenSection'
import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import { useRequest } from '@nord/ui/src/hooks'
import useDeepCompareEffect from '@nord/ui/src/hooks/useDeepCompareEffect'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import classNames from 'classnames'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'

import { useAppContext } from '../../../context/AppContext'
import useIsPayoutAccount from '../../../hooks/useIsPayoutAccount'
import PortfolioStatePage from '../PortfolioStatePage'

import ChartContainer from './ChartContainer'
import formatDataForChart from './formatDataForChart'
import Header from './Header'
import useUpdatePortfolioReturnInDkk from './hooks/useUpdatePortfolioReturnInDkk'
import useUpdatePortfolioTotalValue from './hooks/useUpdatePortfolioTotalValue'
import styles from './index.module.scss'

const ChartPage = () => {
  const { dateFrom, dateTo, chartType } = useAppContext()
  const [chartData, setChartData] = useState()

  const {
    // @ts-expect-error TS(2339) FIXME: Property 'appState' does not exist on type 'unknow... Remove this comment to see the full error message
    appState: portfolioAppState,
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
    // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'unknown'.
    title: portfolioTitle,
  } = useObjectSelector(selectCurrentPortfolio)

  const isPayoutAccount = useIsPayoutAccount()
  const isInvested = portfolioAppState === 'invested'
  const shouldCallChartData = !isPayoutAccount && isInvested

  const { data: rawChartData, isInitialLoading } = useRequest(
    getPortfolioChart,
    [{ portfolioId, chartType, dateFrom, dateTo }],
    {
      enabled: shouldCallChartData,
      staleTime: 60_000,
      keepPreviousData: true,
    },
  )

  const { data: liveData, isLoading: loadingLiveData } = useRequest(
    getPortfolioChartLive,
    [{ portfolioId, chartType, dateFrom, dateTo }],
    {
      refetchInterval: 60_000,
    },
  )

  useUpdatePortfolioTotalValue(liveData, loadingLiveData)

  useUpdatePortfolioReturnInDkk(liveData, loadingLiveData)

  const { formattedData, formatting: formattingChartData } = formatDataForChart(
    rawChartData,
    chartType,
  )

  useDeepCompareEffect(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ timestamp: number; value: any;... Remove this comment to see the full error message
    if (!formattingChartData) setChartData(formattedData)
  }, [formattingChartData, formattedData])

  // @ts-expect-error TS(2339) FIXME: Property 'length' does not exist on type 'never'.
  const isChartReady = portfolioAppState === 'invested' && chartData && chartData.length >= 2

  if (isInitialLoading && shouldCallChartData)
    return (
      <FullScreenSection height="full">
        <Col>
          <Loader className="text-center font-size-xxl" />
        </Col>
      </FullScreenSection>
    )

  return (
    <div
      className={classNames(
        styles.chartWrapper,
        'd-flex',
        'flex-column',
        'justify-content-center',
        'align-items-center',
        'h-100',
      )}
    >
      <Text as="h5" align="center" className="d-none d-md-block">
        {portfolioTitle}
      </Text>
      <Header
        className={classNames(styles.dataWrapper, 'pt-2')}
        isLoading={loadingLiveData}
        liveData={liveData}
        chartType={chartType}
      />
      {isChartReady ? (
        <ChartContainer data={chartData} chartType={chartType} />
      ) : (
        <PortfolioStatePage />
      )}
    </div>
  )
}

export default ChartPage
