import Circle from '@nord/ui/src/components/Circle'
import Icon from '@nord/ui/src/components/Icon'
import Text from '@nord/ui/src/components/Text'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import User from 'react-feather/dist/icons/user'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import UserCheck from 'react-feather/dist/icons/user-check'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import UserPlus from 'react-feather/dist/icons/user-plus'

const referralVariants = {
  created: {
    icon: User,
    variant: 'secondary',
  },
  invested: {
    icon: UserCheck,
    variant: 'info',
  },
  paid: {
    icon: UserPlus,
    variant: 'dark-green',
  },
}

const referralAliases = {
  eligible: 'invested',
}

export interface Props {
  amount: number
  humanizedLastUpdatedAt: string
  message: string
  status: string
  title: string
}

const ReferralRow = ({ amount, humanizedLastUpdatedAt, title, message, status }: Props) => {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const { variant, icon } = referralVariants[status] || referralVariants[referralAliases[status]]

  return (
    <Row as="li" className="border-bottom py-2">
      <Col xs={9}>
        <div className="d-flex align-items-center">
          <div>
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; size... Remove this comment to see the full error message */}
            <Circle className="shadow-none" size="sm" variant={variant}>
              <Icon icon={icon} size="16" />
            </Circle>
          </div>
          <div>
            <Text as="div" align="left" className="pl-3">
              {title}
            </Text>
            <Text as="div" align="left" variant="secondary" className="pl-3">
              {message}
            </Text>
          </div>
        </div>
      </Col>
      <Col xs={3}>
        <Text as="div" align="right" className="mb-0">
          {numberToCurrency(amount)}
        </Text>
        <Text as="div" align="right" variant="secondary">
          Opdateret: {humanizedLastUpdatedAt}
        </Text>
      </Col>
    </Row>
  )
}

export default ReferralRow
