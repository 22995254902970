import classNames from 'classnames'
import React from 'react'
import { Nav as BootstrapNav } from 'react-bootstrap'

import AnimatedLink from '../AnimatedLink'
import InternalLink from '../InternalLink'

const withAnimatedLink = (as: any) => {
  const WrapperComponent = (props: any) => <AnimatedLink as={as} {...props} />

  WrapperComponent.displayName = 'AnimatedLink'

  return WrapperComponent
}

export interface NavLinkProps {
  animated?: boolean
  as?: React.ReactElement
  className?: string[] | string
}

const NavLink = ({ className, animated, as, ...otherProps }: NavLinkProps) => {
  const Component = animated ? withAnimatedLink(as) : as

  return (
    <BootstrapNav.Link
      // @ts-expect-error TS(2322) FIXME: Type 'ReactElement<any, string | JSXElementConstru... Remove this comment to see the full error message
      as={Component}
      className={classNames('nav-link', className, { 'link-light': !animated })}
      {...otherProps}
    />
  )
}

NavLink.defaultProps = {
  animated: undefined,
  as: InternalLink,
  className: undefined,
}

export default NavLink
