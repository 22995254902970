import classNames from 'classnames'
import React, { useRef, useContext, useEffect } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'

import useBreakpointHandler from '../../hooks/useBreakpointHandler'
import useLockBodyScroll from '../../hooks/useLockBodyScroll'
import useUncontrollableProp from '../../hooks/useUncontrollableProp'
import extractTransitionClasses from '../../utilities/extractTransitionClasses'
import Header from '../Header'
import { PageSwitchContext } from '../PageSwitchTransition'

import styles from './index.module.scss'
import NavItem from './NavItem'
import NavLink from './NavLink'

const toggleButtonTimeout = parseInt(styles.toggleButtonTimeout, 10)
const sidebarMenuTimeout = parseInt(styles.sidebarMenuTimeout, 10)
const menuLinkOffset = parseInt(styles.menuLinkOffset, 10)

export interface Props {
  className?: string
  fluid?: boolean
  footer?: React.ReactNode
  leftNav?: React.ReactNode[]
  onToggleMobileNav?: (...args: any[]) => any
  rightNav?: React.ReactNode[]
  showMobileNav?: boolean
  variant?: 'dark' | 'light'
}

const NavMenu = ({
  className,
  fluid,
  leftNav,
  rightNav,
  footer,
  variant,
  showMobileNav,
  onToggleMobileNav,
  ...otherProps
}: Props) => {
  const sidebarRef = useRef(null)
  const { active: pageTransitionActive } = useContext(PageSwitchContext)

  const [showMobile, handleChangeMobileNav] = useUncontrollableProp(
    showMobileNav,
    // @ts-expect-error TS(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
    false,
    onToggleMobileNav,
  )

  const expanded = useBreakpointHandler(styles.expandBreakpoint)

  useEffect(() => {
    if (expanded || pageTransitionActive) handleChangeMobileNav(false)
  }, [expanded, handleChangeMobileNav, pageTransitionActive])

  useLockBodyScroll(!expanded && showMobile)

  const handleToggleExpanded = () => {
    handleChangeMobileNav(!showMobile)
  }

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const linkAnimationTimeout = (leftNav.length + rightNav.length - 1) * menuLinkOffset

  const linkDelay = (index: any) => {
    if (!pageTransitionActive) return 0

    let delay = showMobile ? sidebarMenuTimeout : linkAnimationTimeout
    const offset = index * menuLinkOffset

    if (showMobile) {
      delay += offset
    } else {
      delay -= offset
    }

    return delay
  }

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: (ReactNode | Element)[]; variant... Remove this comment to see the full error message
    <Header variant={variant} sticky mobile>
      <Navbar
        // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'boolean |... Remove this comment to see the full error message
        expand={styles.expandBreakpoint}
        role="banner"
        variant={variant}
        className={classNames(styles.navbar, `children-mx-${styles.expandBreakpoint}-2`)}
        collapseOnSelect
        {...otherProps}
      >
        <div
          className={classNames({ 'container-xl': !fluid }, { 'container-fluid mx-lg-5': fluid })}
        >
          {/* @ts-expect-error TS(2322) FIXME: Type '{ className: string; variant: "dark" | "ligh... Remove this comment to see the full error message */}
          <Header.Logo className="mr-4" variant={variant === 'light' ? 'dark' : 'light'} mobile />
          <CSSTransition
            classNames={extractTransitionClasses({
              styles,
              className: 'toggleButton',
            })}
            in={showMobile}
            timeout={toggleButtonTimeout}
          >
            <button
              aria-controls="navbar-mobile"
              aria-label="Åben/luk menuen"
              type="button"
              data-cy="burger-mobile-menu"
              className={classNames('float-right', styles.toggleButton)}
              onClick={handleToggleExpanded}
            >
              <div className={styles.firstLine}>
                <div className={styles.lineCollapse}>
                  <div className={styles.lineRotate}>
                    <div className={styles.line} />
                  </div>
                </div>
              </div>

              <div className={styles.middleLine}>
                <div className={styles.line} />
              </div>

              <div className={styles.lastLine}>
                <div className={styles.lineCollapse}>
                  <div className={styles.lineRotate}>
                    <div className={styles.line} />
                  </div>
                </div>
              </div>
            </button>
          </CSSTransition>
          <CSSTransition
            classNames={extractTransitionClasses({
              styles,
              className: 'sidebarMobile',
            })}
            in={showMobile}
            timeout={sidebarMenuTimeout * 2 + linkAnimationTimeout}
          >
            <div
              id="navbar-mobile"
              ref={sidebarRef}
              className={classNames('navbar-collapse', styles.sidebarMobile)}
              style={{
                transitionDelay: `${showMobile ? 0 : linkAnimationTimeout + sidebarMenuTimeout}ms`,
              }}
            >
              <Nav className="d-flex align-items-center">
                {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
                {leftNav.map((link, index) =>
                  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                  React.cloneElement(link, {
                    // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
                    key: link.props.key || link.props.href || index,
                    style: {
                      // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
                      ...link.props.style,
                      transitionDelay: `${linkDelay(index)}ms`,
                    },
                  }),
                )}
              </Nav>

              <Nav
                className={classNames(
                  `ml-${styles.expandBreakpoint}-auto`,
                  'd-flex',
                  'align-items-center',
                )}
              >
                {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
                {rightNav.map((link, index) =>
                  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                  React.cloneElement(link, {
                    // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
                    key: link.props.key || link.props.href || index,
                    style: {
                      // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
                      ...link.props.style,
                      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                      transitionDelay: `${linkDelay(leftNav.length + index)}ms`,
                    },
                  }),
                )}
              </Nav>
            </div>
          </CSSTransition>
        </div>
      </Navbar>
      {footer}
    </Header>
  )
}

NavMenu.defaultProps = {
  className: undefined,
  fluid: false,
  footer: undefined,
  leftNav: [],
  onToggleMobileNav: undefined,
  rightNav: [],
  showMobileNav: undefined,
  variant: 'dark',
}

NavMenu.Item = NavItem
NavMenu.Link = NavLink

export default NavMenu
