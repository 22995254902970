import { getConfig } from '@nord/ui/src/configuration'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'

const { simple: defaultMinimumInvestment, pension: pensionMinimumInvestment } = getConfig(
  'numbers.minimumInvestment',
)

const useDepositReceivedData = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'currentDepositInDkk' does not exist on t... Remove this comment to see the full error message
  const { currentDepositInDkk, pensionDepot } = useObjectSelector(selectCurrentPortfolio)

  const minimumInvestment = pensionDepot ? pensionMinimumInvestment : defaultMinimumInvestment

  const depositIsEligibeToInvest = currentDepositInDkk >= minimumInvestment

  const message = depositIsEligibeToInvest
    ? 'Vi har modtaget din indbetaling og vil investere dine penge for dig hurtigst muligt, senest inden for to hverdage efter vi har modtaget overførslen.'
    : `Vi har modtaget din indbetaling, men dit kontantbeløb på din konto er desværre ikke nok til at starte din investering. Der kræves minimum ${numberToCurrency(
        minimumInvestment,
      )} før vi investere for dig første gang.`

  return { title: 'Din indbetaling er modtaget', message }
}

export default useDepositReceivedData
