import CurrencyInput from '@nord/ui/src/components/CurrencyInput'
import Form from '@nord/ui/src/components/Form'
import Select from '@nord/ui/src/components/Select'
import Text from '@nord/ui/src/components/Text'
import useApi from '@nord/ui/src/hooks/useApi'
import useQuery from '@nord/ui/src/hooks/useQuery'
import { selectPortfolios } from '@nord/ui/src/store/current/portfolios'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'
import { object, number } from 'yup'

import usePageLeaveWarning from '../../../../hooks/usePageLeaveWarning'

import usePensionWarning from './usePensionWarning'

const labelDescriptions = {
  portfolioId: 'Vælg NORD konto',
  amount: 'Beløb',
  transactionText: 'Tekst til din egen bank',
}

const validationSchema = object().shape({
  amount: number().min(1).required(),
})

const StartPage = () => {
  const portfoliosData = useSelector(selectPortfolios)
  const portfolioIdQueryParam = useQuery('portfolioId')

  usePageLeaveWarning()

  const filteredPortfoliosData = portfoliosData.filter(
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    ({ createdInSaxoAt, depotType }) => !!createdInSaxoAt && depotType !== 'pensions_udbetaling',
  )
  const hasOnlyOnePortfolio = filteredPortfoliosData.length === 1
  const portfoliosOptions = filteredPortfoliosData.map(
    // @ts-expect-error TS(2345) FIXME: Argument of type '({ id, title, registrationNumber... Remove this comment to see the full error message
    ({ id, title, registrationNumber, saxoAccountNumber, totalValue }) => {
      const totalValueFormatted = numberToCurrency(totalValue)

      return {
        value: id,
        label: `${title}: ${registrationNumber} - ${saxoAccountNumber} (${totalValueFormatted})`,
      }
    },
  )

  let defaultPortfolioId
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  if (hasOnlyOnePortfolio) defaultPortfolioId = portfoliosOptions[0].value
  if (portfolioIdQueryParam) defaultPortfolioId = parseInt(portfolioIdQueryParam, 10)

  const [portfolioId, setPortfolioId] = useState(defaultPortfolioId)

  const pensionWarning = usePensionWarning(portfolioId)

  const depositCash = useApi(`/users/payments`, {
    method: 'POST',
    withCredentials: true,
  })

  const handleDeposit = async ({ amount, transactionText }: any) => {
    const { data, success } = await depositCash({
      payment: { amount, transactionText, portfolioId },
    })

    if (!success) return

    const { redirectUrl } = data

    window.location.replace(redirectUrl)
  }

  return (
    <>
      <Text as="p" align="center">
        Du kan nemt indbetale penge til din portefølje, og vi vil investere dem med det samme for
        dig, hvis du har{' '}
        {portfolioId ? (
          <Link to={`/portefoeljer/${portfolioId}/forhandsgodkendelse`} title="Forhåndsgodkendelse">
            forhåndsgodkendt
          </Link>
        ) : (
          'forhåndsgodkendt'
        )}{' '}
        dette. Vær opmærksom på, at det kan tage op til 2 bankdage før pengene er på din konto.
      </Text>
      {pensionWarning && <Alert variant="warning">{pensionWarning}</Alert>}
      <Form
        labelDescriptions={labelDescriptions}
        validationSchema={validationSchema}
        onSubmit={handleDeposit}
        initialValues={{
          portfolioId,
          transactionText: 'NORD.investments indbetaling',
        }}
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; labelDescriptions: { ... Remove this comment to see the full error message
        className="text-left"
        enableReinitialize
      >
        <Form.FieldGroup name="portfolioId">
          <Form.Field
            // @ts-expect-error TS(2739) FIXME: Type '{ ({ onChange, onValueChange, onSelect, opti... Remove this comment to see the full error message
            inputComponent={Select}
            options={portfoliosOptions}
            onValueChange={setPortfolioId}
            isSearchable={false}
            placeholder="Vælg konto..."
          />
        </Form.FieldGroup>
        <Form.FieldGroup name="amount">
          {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
          <Form.Field as={CurrencyInput} />
        </Form.FieldGroup>
        <Form.FieldGroup name="transactionText">
          <Form.Field />
        </Form.FieldGroup>
        <div className="d-flex justify-content-center my-4">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; }' is n... Remove this comment to see the full error message */}
          <Form.SubmitButton variant="primary">Indbetal</Form.SubmitButton>
        </div>
      </Form>
    </>
  )
}

export default StartPage
