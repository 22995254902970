import Card from '@nord/ui/src/components/Card'
import DescriptionList from '@nord/ui/src/components/DescriptionList'
import IconButton from '@nord/ui/src/components/IconButton'
import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import queryString from 'query-string'
import React from 'react'
import Button from 'react-bootstrap/Button'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Clipboard from 'react-feather/dist/icons/clipboard'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

const NewDeposit = () => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'registrationNumber' does not exist on ty... Remove this comment to see the full error message
    registrationNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'saxoAccountNumber' does not exist on typ... Remove this comment to see the full error message
    saxoAccountNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
  } = useObjectSelector(selectCurrentPortfolio)
  const { accountType } = useSelector(selectCurrentUser)
  const isCompany = accountType === 'company_account'

  const { addToast } = useToasts()

  const handleCopyAccountNumber = () => {
    navigator.clipboard.writeText(saxoAccountNumber)
    addToast('Kontonummeret er kopieret til din udklipsholder.', {
      appearance: 'info',
      autoDismiss: true,
    })
  }

  const handleCopyRegistrationNumber = () => {
    navigator.clipboard.writeText(registrationNumber)
    addToast('Registreringsnummeret er kopieret til din udklipsholder.', {
      appearance: 'info',
      autoDismiss: true,
    })
  }

  return (
    <>
      {!isCompany && (
        <>
          <Text as="h4" align="center" className="mt-3">
            Nem indbetaling
          </Text>
          <Text as="p" align="center">
            Vælg blandt dine bankkonti, og lav en nem og gratis overførsel til din NORD konto i Saxo
            Bank.
          </Text>
          <div className="text-center mb-4">
            <Button
              as={Link}
              to={{
                pathname: '/konto/kontant_indbetaling/opret',
                // eslint-disable-next-line camelcase
                search: queryString.stringify({ portfolio_id: portfolioId }),
              }}
            >
              Ny nem overførsel
            </Button>
          </div>
        </>
      )}

      <Text as="h4" align="center">
        {isCompany ? 'Kontooverførsel' : 'Traditionel kontooverførsel'}
      </Text>

      <Text as="div" align="center" className="mt-3">
        Lav en kontooverførsel til nedenstående konto, for at indbetale til din investering.
      </Text>

      <Text as="div" align="center" className="mb-4">
        Du kan lave en månedsopsparing ved at oprette en løbende indbetaling fra din netbank.
      </Text>

      <Card className="shadow mb-3 mx-lg-5 mb-4">
        <Card.Body className="mx-lg-5">
          <DescriptionList className="text-center text-lg-left">
            <DescriptionList.Item
              label="Registreringsnummer"
              xs={12}
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; xs: number; lg: number; val... Remove this comment to see the full error message
              lg={6}
              value={
                <>
                  {registrationNumber}
                  <IconButton
                    icon={Clipboard}
                    onClick={handleCopyRegistrationNumber}
                    title="Kopier til udklipsholder"
                    as={Button}
                    variant="link"
                    className="btn-anchor ml-2"
                  />
                </>
              }
            />

            <DescriptionList.Item
              label="Kontonummer"
              xs={12}
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; xs: number; lg: number; val... Remove this comment to see the full error message
              lg={6}
              value={
                <>
                  {saxoAccountNumber}
                  <IconButton
                    icon={Clipboard}
                    onClick={handleCopyAccountNumber}
                    title="Kopier til udklipsholder"
                    as={Button}
                    variant="link"
                    className="btn-anchor ml-2"
                  />
                </>
              }
            />
          </DescriptionList>
        </Card.Body>
      </Card>
    </>
  )
}

export default NewDeposit
