import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import formatDate from '@nord/ui/src/utilities/formatDate'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { preferenceLevelAliases, preferenceLevels } from './data'

const sustainabilityPreferenceStartDate = new Date(2022, 6, 25)

const Sustainability = () => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
    // @ts-expect-error TS(2339) FIXME: Property 'sustainabilityPreferenceLevel' does not ... Remove this comment to see the full error message
    sustainabilityPreferenceLevel,
    // @ts-expect-error TS(2339) FIXME: Property 'sustainabilityPreferenceGoalAnswer' does... Remove this comment to see the full error message
    sustainabilityPreferenceGoalAnswer,
  } = useObjectSelector(selectCurrentPortfolio)

  const withSustainabilityPreference = !!sustainabilityPreferenceLevel

  const text =
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    preferenceLevels[sustainabilityPreferenceLevel] ||
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    preferenceLevels[preferenceLevelAliases[sustainabilityPreferenceLevel]]

  const formattedText =
    typeof text === 'function' ? text({ answer: sustainabilityPreferenceGoalAnswer }) : text

  if (withSustainabilityPreference)
    return (
      <>
        <Text as="p" align="center">
          Her kan du se dine præferencer i forhold til bæredygtig investering for porteføljen.
        </Text>
        <Text as="p" align="center">
          {formattedText}
        </Text>
        <Text as="p" align="center">
          <Link to={`/portefoeljer/${portfolioId}/anbefaling/sporgsmal`}>
            Ønsker du at ændre dine præferencer i forhold til bæredygtig investering? Tryk her.
          </Link>
        </Text>
      </>
    )

  return (
    <>
      <Text as="p" align="center">
        Sidst du fik en investeringsanbefaling fra NORD var før den{' '}
        {formatDate(sustainabilityPreferenceStartDate, 'PPP')}, og inden vi kortlagde vores kunders
        præferencer i forhold til bæredygtig investering.
      </Text>
      <Text as="p" align="center">
        Ønsker du at registrere dine præferencer i forhold til bæredygtig investering og modtage en
        ny investeringsanbefaling?
      </Text>
      <Text as="p" align="center">
        <Link to={`/portefoeljer/${portfolioId}/anbefaling/sporgsmal`}>
          Ønsker du at ændre dine præferencer i forhold til bæredygtig investering? Tryk her.
        </Link>
      </Text>
    </>
  )
}

export default Sustainability
