import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import React from 'react'
import Modal from 'react-bootstrap/Modal'

import styles from './Modal.module.scss'

const contactInformation = getConfig('contactInformation')
const websiteUrls = getConfig('urls.website')

export interface Props {
  onHide: (...args: any[]) => any
  show: boolean
}

const ConsentTermsModal = ({ onHide, show }: Props) => {
  // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'unknown'.
  const { email } = useObjectSelector(selectCurrentUser)

  return (
    <Modal
      className="text-dark"
      onHide={onHide}
      show={show}
      size="lg"
      backdropClassName={styles.backdropBackground}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center" data-cy="modal-title">
          Betingelser for samtykke
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text as="p">
          For at kunne tilbyde belønningen har vi behov for at behandle personoplysninger om dig i
          form af dit navn og din e-mailadresse. Med dit samtykke giver du tilladelse til, at:
        </Text>
        <ul>
          <li>
            NORD.investments registrering af dit navn og e-mailadresse (foruden registreringen som
            følge af dit kundeforhold hos NORD.investments) sker med det formål, at du kan anbefale
            NORD.investments og modtage belønning.
          </li>
          <li>
            Dit navn og din e-mail {email} videregives af NORD.investments via en personlig
            hjemmeside til de venner, du videresender linket til.
          </li>
        </ul>
        <Text as="p">
          I forbindelse med behandlingen af personoplysningerne optræder NORD.investsments som
          dataansvarlig. Du kan altid trække din tilladelse tilbage enten ved at fjerne markeringen
          eller ved at kontakte NORD.investments via{' '}
          <Text as="a" href={contactInformation.emailLink}>
            {contactInformation.email}
          </Text>
          . Du skal dog være opmærksom på, at en tilbagetrækning af din tilladelse til os til at
          benytte dine personoplysninger alene får betydning for vores behandling og deling af dine
          oplysninger fremadrettet.
        </Text>
        <Text as="p">
          Du kan læse mere om NORD.investments behandling af personoplysninger i vores
          privatlivspolitik, som du finder på vores hjemmeside:{' '}
          <Text as="a" href={websiteUrls.frontpage}>
            {websiteUrls.frontpage}
          </Text>
        </Text>
      </Modal.Body>
    </Modal>
  )
}

export default ConsentTermsModal
